<template>
  <div id="invoiceList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>发票管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">关键词:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">税号:</span>
            <el-input
              placeholder="请输入税号"
              v-model="filtrate.taxNum"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">选择停车场:</span>
            <el-select
              v-model="filtrate.parkingLotIds"
              multiple
              collapse-tags
              placeholder="请选择停车场"
              class="parkingLotIds"
              @change="getList(1)"
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择开票状态:</span>
            <el-select
              v-model="filtrate.invoiceStatus"
              filterable
              placeholder="请选择开票状态"
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_invoiceStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">订单类型:</span>
            <el-select
              v-model="filtrate.orderInvoiceType"
              placeholder="请订单类型"
              @change="getList(1)"
            >
              <el-option label="停车订单" value="停车订单"> </el-option>
              <el-option label="商户充值订单" value="商户充值订单"> </el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              type="primary"
              :disabled="!selection.length"
              @click="handleSelectionClick"
              v-if="buttonList.includes('adviceList-agree')"
              >批量同意</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        :row-key="handleRowKey"
        style="width: 100%"
        @expand-change="expandChange"
        @selection-change="handleSelectionChange"
        v-loading="table.loading"
        ref="table"
      >
        <el-table-column
          type="selection"
          width="55"
          :reserve-selection="true"
          :selectable="selectable"
        />
        <el-table-column type="expand" align="center">
          <template slot-scope="props">
            <div v-if="props.row.children.length > 0">
              <el-table
                v-if="filtrate.orderInvoiceType === '停车订单'"
                :data="props.row.children"
                row-key="orderId"
                class="table-expand"
              >
                <el-table-column label="订单号" align="center">
                  <template slot-scope="scope">
                    <span
                      class="orderId"
                      @click="goToPage(scope.row.orderId)"
                      >{{ scope.row.orderId }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="plateNum" label="车牌号" align="center">
                </el-table-column>
                <el-table-column
                  prop="parkingLotName"
                  label="停车场名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="parkingTime"
                  label="停车时长"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="priceName"
                  label="套餐名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="couponPrice"
                  label="优惠金额"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="walletAmount"
                  label="钱包支付金额"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="payAmount"
                  label="实际支付金额"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="payMethod"
                  label="支付方式"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="platformType"
                  label="支付平台"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="payTime" label="支付时间" align="center">
                </el-table-column>
              </el-table>
              <el-table v-else :data="props.row.children" class="table-expand">
                <el-table-column label="订单号" align="center">
                  <template slot-scope="scope">
                    <span
                      class="orderId"
                      @click="goToPage(scope.row.orderId)"
                      >{{ scope.row.orderId }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="parkingLotName"
                  label="停车场名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="merchantName"
                  label="商户名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="payAmount"
                  label="实际支付金额"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="orderTime"
                  label="订单时间"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
            <div v-else class="notData">暂无数据</div>
          </template>
        </el-table-column>
        <el-table-column prop="payAmount" label="支付金额" align="center">
        </el-table-column>
        <el-table-column prop="buyerName" label="抬头" align="center">
        </el-table-column>
        <el-table-column prop="buyerTaxNum" label="纳税人识别号" align="center">
        </el-table-column>
        <el-table-column prop="buyerAccount" label="购方账号" align="center">
        </el-table-column>
        <el-table-column prop="buyerAddress" label="购方地址" align="center">
        </el-table-column>
        <el-table-column prop="status" label="开票状态" align="center">
        </el-table-column>
        <el-table-column prop="failCause" label="失败原因" align="center">
        </el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="scope">
            <div class="paperUrlList">
              <el-image
                v-for="item of scope.row.paperUrlList"
                :key="item"
                class="invoiceImg"
                fit="contain"
                :src="item"
                :preview-src-list="[item]"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceTime" label="开票时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="agree(scope.row)"
              v-if="
                (scope.row.status === '发票审核中' ||
                  scope.row.status === '冲红审核中') &&
                buttonList.includes('adviceList-agree')
              "
              >同意
            </el-button>
            <el-button
              type="text"
              @click="openWindow(scope.row.pdfUrl)"
              v-if="scope.row.pdfUrl && buttonList.includes('adviceList-PDF')"
              >查看PDF
            </el-button>
            <el-button
              type="text"
              @click="cancellation(scope.row.orderInvoiceId)"
              v-if="
                buttonList.includes('adviceList-cancellation') &&
                scope.row.status === '开票完成'
              "
              :disabled="scope.row.invoiceCancel === '是'"
              >{{
                scope.row.invoiceCancel === "是" ? "已冲红" : "冲红"
              }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        keyword: "",
        taxNum: "",
        parkingLotIds: [],
        invoiceStatus: "",
        orderInvoiceType: "停车订单",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
      selection: [],
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  watch: {
    parkingLotList: {
      handler(list) {
        this.filtrate.parkingLotIds = list.map((item) => item.parkingLotId);
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    reset() {
      this.filtrate = {
        keyword: "",
        taxNum: "",
        parkingLotIds: [],
        invoiceStatus: "",
        orderInvoiceType: "停车订单",
      };
      this.getList(1);
    },
    async expandChange(row, expandedRows) {
      if (expandedRows.length > 0) {
        const methods =
          this.filtrate.orderInvoiceType === "商户充值订单" ? "get" : "post";
        const url =
          this.filtrate.orderInvoiceType === "商户充值订单"
            ? `/order/merchant/list/${row.orderInvoiceId}`
            : `/order/list/byInvoice/${row.orderId}`;

        let res = await this.$http[methods](url);
        if (res.code === 0) {
          for (let item of res.data) {
            item.payTime = item.payTime
              ? this.$moment(item.payTime).format("yyyy-MM-DD HH:mm:ss")
              : "";
            item.orderTime = item.orderTime
              ? this.$moment(item.orderTime).format("yyyy-MM-DD HH:mm:ss")
              : "";
            item.random = `children-${item.orderInvoiceId}`;
          }
          for (let item of this.table.tableData) {
            if (item.orderId === row.orderId) {
              item.children = res.data;
              break;
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      } else {
        for (let item of this.table.tableData) {
          if (item.orderId === row.orderId) {
            item.children = [];
            break;
          }
        }
      }
    },
    goToPage(orderId) {
      this.$router.push({
        name: "stopOrderList",
        params: {
          keyword: orderId,
        },
      });
    },
    cancellation(orderInvoiceId) {
      this.$prompt("", "冲红", {
        inputPlaceholder: "请输入备注",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async ({ value }) => {
          try {
            let res = await this.$http.post("/invoice/cancellation", {
              orderInvoiceId,
              remarks: value,
            });
            if (res.code === 0) {
              this.$message.success("操作成功");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            this.$message.error(err);
          }
        })
        .catch(() => {});
    },
    handleRowKey(row) {
      // console.log(row.random);
      return row.random;
    },
    agree(row) {
      this.$confirm("下一步将完成同意操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";

            try {
              let res = await this.$http.post("/invoice/request/review", [
                row.orderInvoiceId,
              ]);
              if (res.code === 0) {
                this.$message.success("操作成功");
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            } catch (err) {
              this.$message.error(err);
            } finally {
              done();
              instance.confirmButtonLoading = false;
            }
          } else {
            done();
          }
        },
      });
    },
    selectable(row) {
      return row.status === "发票审核中" || row.status === "冲红审核中";
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    handleSelectionClick() {
      this.$confirm(
        `您选中 ${this.selection.length} 条记录，是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";
              const successList = [];

              try {
                for (const item of this.selection) {
                  let res = await this.$http.post("/invoice/request/review", [
                    item.orderInvoiceId,
                  ]);
                  if (res.code === 0) {
                    successList.push(item.orderInvoiceId);
                  }
                }
              } catch (err) {
                this.$message.error(err);
              } finally {
                done();
                instance.confirmButtonLoading = false;

                this.$notify({
                  title: "批量操作执行结果",
                  dangerouslyUseHTMLString: true,
                  message: `
                  <p>
                    成功：<strong>${successList.length}</strong> 条
                  </p>
                  <p>
                    失败：<strong>${
                      this.selection.length - successList.length
                    }</strong> 条
                  </p>
                `,
                  duration: 0,
                });
              }
            } else {
              done();
            }
          },
        }
      );
    },
    async getList(page = this.table.page) {
      this.$nextTick(() => {
        for (const item of this.table.tableData) {
          this.$refs.table.toggleRowExpansion(item, false);
        }
      });

      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/invoice/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
          taxNum: this.filtrate.taxNum,
          orderInvoiceType: this.filtrate.orderInvoiceType,
          parkingLotIds: this.filtrate.parkingLotIds
            ? this.filtrate.parkingLotIds
            : null,
          invoiceStatus: this.filtrate.invoiceStatus
            ? this.filtrate.invoiceStatus
            : null,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.invoiceTime = this.$moment(item.invoiceTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.children = [];
            item.random = item.orderInvoiceId;
            item.paperUrlList = item.paperUrl ? item.paperUrl.split(",") : [];
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    openWindow(pdfUrl) {
      window.open(pdfUrl, "_blank");
    },
  },
};
</script>
<style lang="scss">
#invoiceList {
  .parkingLotIds {
    .el-input {
      width: 200px !important;
    }
  }
  .invoiceImg {
    width: 50px;
    height: 50px;
  }
  .el-table__expanded-cell {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: transparent;
  }
  .notData {
    text-align: center;
  }
  .table-expand {
    &::before,
    .el-table__body-wrapper:after,
    .el-table__body-wrapper::before {
      background-color: #283b63 !important;
    }
    .el-table__header {
      th {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
    .cell {
      color: $main-color !important;
    }
    .orderId {
      cursor: pointer;
    }
  }
  .paperUrlList {
    display: flex;
    justify-content: center;
  }
}
</style>
